import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import PortfolioDetail, { ProjectsProps } from "./PortfolioDetail"

export interface propsType {
  projectList?: ProjectsProps[]
  showBudget?: boolean
  showProjectTechnologies?: boolean
  clutchButtonClass?: string
  navigateButtonType?: 1 | 2
}


const PortfolioList = ({ projectList, showBudget = true, showProjectTechnologies = false, clutchButtonClass = "", navigateButtonType = 1 }: propsType) => {
  let projects = projectList
  if (!projects) {
    projects = useStaticQuery(graphql`
      query {
        allProjectSlideJson {
          projects: nodes {
            slug
            title
            subTitle
            idx
            description
            clutchLink
            industry
            country
            budget
            backgroundStyles
            featuredImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 70)
              }
              publicURL
            }
          }
        }
      }
    `)?.allProjectSlideJson?.projects
  }

  return projects?.map((project: ProjectsProps) => (
    <PortfolioDetail key={`portfolio-${project.idx}`} {...project} showBudget={showBudget} showProjectTechnologies={showProjectTechnologies} clutchButtonClass={clutchButtonClass} navigateButtonType={navigateButtonType} />
  ))
}

export default PortfolioList
