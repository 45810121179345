import { HeadFC, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import AgencyReviewSlider from "../components/AgencyReviewSlider"
import Contact from "../components/Contact"
import ProjectPortfolio from "../components/DashboardPortfolio/PortfolioList"
import IndustryInsights from "../components/IndustryInsights"
import SEO from "../components/SEO"
import Technologies from "../components/Technologies"
import { useContextValues } from "../context/NavBarContext"
import useSiteMetadata from "../hooks/useSiteMetadata"
import TestimonialSlider from "../components/DashboardTestimonials/TestimonialSlider"

import callWhiteAnim from "../assets/img/call-white.gif"
import callBlackAnim from "../assets/img/call-black.gif"
import StreamComponent from "../components/StreamComponent"
// import Banner from "../assets/img/banner.webp"

const Home = () => {
  const [showComponent, setShowComponent] = useState(false)
  const { isMobile, changeNavColor, changeNavPosition, changeCurrentPath } =
    useContextValues()

  const handleScroll = () => {
    if (window.scrollY > 1000) {
      setShowComponent(true)
    }
  }

  useEffect(() => {
    changeCurrentPath(location.pathname)
    changeNavPosition("absolute")
    changeNavColor("white")
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      changeCurrentPath("")
      changeNavColor("black")
      changeNavPosition("fixed")
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  return (
    <>
      <section className={`bg-[#0f1111] pt-[7.5rem] pb-[4rem] md:pb-24 lg:py-32 md:h-screen flex flex-wrap max-sm:justify-center items-center main-banner-sec relative overflow-x-hidden max-sm:gap-[30px] sm:h-[400px]`}>
        <div className="container max-w-[82.5rem] ">
          <h1 className="text-[4.375rem] text-white font-blanco leading-[90px]">
            <p className="newBannerText">
              <span className="relative before:w-[12px] before:h-[12px] before:content-[''] before:absolute before:bg-[#ff3d2e] before:rounded-full before:bottom-[14px] before:right-0 pr-[18px] mr-[15px] max-sm:pr-[12px] max-sm:mr-[10px]  max-md:before:w-[7px] max-md:before:h-[7px] max-md:before:bottom-[5px]">Build</span>
              <span className="relative before:w-[12px] before:h-[12px] before:content-[''] before:absolute before:bg-[#ff3d2e] before:rounded-full before:bottom-[14px] before:right-0 pr-[18px] mr-[15px] max-sm:pr-[12px] max-sm:mr-[10px]  max-md:before:w-[7px] max-md:before:h-[7px] max-md:before:bottom-[5px]">Innovate</span>
              <span className="relative before:w-[12px] before:h-[12px] before:content-[''] before:absolute before:bg-[#ff3d2e] before:rounded-full before:bottom-[14px] before:right-0 pr-[18px] mr-[15px] max-sm:pr-[12px] max-md:before:w-[7px] max-md:before:h-[7px] max-md:before:bottom-[5px]">Success</span>
            </p>
            <p className="newBannerText">The Smart Way with AI</p>
            <div className="scroll-box">
              <ul>
                <li><p className="text-primary newBannerText">AI-Driven Solutions</p></li>
                <li><p className="text-primary newBannerText">Mobile Innovation</p></li>
                <li><p className="text-primary newBannerText">Web Excellence</p></li>
                <li><p className="text-primary newBannerText">AI-Driven Solutions</p></li>
              </ul>
            </div>

            <Link
              to="/contact-us/"
              className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 justify-center md:justify-start inline-white text-black  shadow-1xl text-base leading-[1.313rem] rounded-[8px] px-[1.75rem] py-[10px] font-gorditamedium group ease-in duration-300 hover:bg-primary max-md:mt-[15px] max-md:py-[5px] max-md:px-[20px] mt-5 md:mt-8 lg:mt-[4.625rem]"
            >
              <span className="hidden md:block mt-[0.125rem] group-hover:text-white transition-all ease-in duration-300">Get a Free 30-Minutes Consultation</span><span className="md:hidden group-hover:text-white mt-[0.125rem]">Get an estimate</span>
              <span className="inline-flex justify-center items-center rounded-full relative h-10 w-10 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent max-sm:w-[2.1875rem] max-sm:h-[2.1875rem]">
                <img src={callWhiteAnim} alt="Get a Free 30-Minutes Consultation" className="w-[2.813rem] h-[2.188rem] absolute top-[0px] left-[2px] opacity-0 invisible transition-all ease-in duration-300 group-hover:opacity-100 group-hover:visible  group-hover:text-white object-contain max-sm:top-[-3px]" />
                <img src={callBlackAnim} alt="Get a Free 30-Minutes Consultation" className="w-[2.813rem] h-[2.188rem] absolute top-[0px] left-[2px] transition-all ease-in duration-300 group-hover:opacity-0 group-hover:invisible object-contain max-sm:top-[-3px]" />
              </span>
            </Link>
          </h1>

        </div>
        <div className="sm:absolute right-[80px] bottom-[80px] md:bottom-[117px] max-sm:hidden md:block">
          <StreamComponent />
        </div>
        <p className="inline-block w-[366px] h-[366px] rounded-full bg-[#57D822] filter blur-[150px] absolute right-[-120px] top-[-120px] opacity-[0.7] blur-shape"></p>
      </section>
      <section className="result-sec lg:py-[3.125rem] xl:py-[7.5rem] md:py-10 pt-10 pb-10 overflow-x-hidden">
        <div className="container">
          <div className="flex flex-wrap justify-center items-center">
            <div className="w-full">
              <div className="md:max-w-[47.5rem] max-w-full m-auto text-center">
                <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-4 font-blancomedium md:max-w-[22.375rem] lg:max-w-[28.625rem] md:my-0 md:mx-auto relative max-[767px]:inline-block  ">
                  12 years. Good work. <br /> <span className="italic text-primary">Fantastic results</span>
                  <span className="inline-block text-orangecol">.</span>
                  <span className="title-decor w-12 h-12 inline-block absolute top-[-10px] right-0 max-[767px]:right-[-3.125rem]">
                    <StaticImage
                      src="../assets/img/gold-star.webp"
                      alt="Star"
                      placeholder="blurred"
                      className="w-full h-full"
                    />
                  </span>
                </h2>
                <p className="lg:text-[1.125rem] lg:leading-[1.875rem]">
                  We are not the new kid on the block; we don’t consider ourselves the “old” players either. Because even after 12 years in the industry, delivering <b className="font-gorditamedium font-normal">250+ projects</b> for clients across 4 continents, and a dozen of awards, we believe we're just getting started.
                </p>
              </div>
            </div>
            <div className=" max-sm:hidden md:block">
              <AgencyReviewSlider />
            </div>
          </div>
        </div>
      </section>
      <section className="our-services pb-6 md:pb-5 lg:pb-[4.875rem]">
        <div className="container">
          {/* <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic md:pt-[0.563rem] md:pb-[0.375rem] pt-[0.5rem] pb-[0.313rem] px-[1.5rem] rounded-[6.25rem] leading-[1.125rem] md:leading-[1.813rem]">Our Services</span></div> */}
          {/* <span className="connector py-[0.625rem] md:py-[1.719rem] block text-center relative before:content-[''] before:w-[2.875rem] before:h-[6.875rem] before:bg-[url('../assets/img/seprator.webp')] md:before:bg-center before:bg-[center_top_-15px] before:bg-no-repeat before:absolute before:top-0 before:left-[50%] before:translate-x-[-50%] before:-z-10 "><span className="rounded inline-block bg-[#D9D9D9] w-2 h-2 "></span></span> */}

          <div className="start-up-ideas relative bg-white-300 md:px-[3.125rem] px-5 md:pt-[2.5rem] pt-7 md:pb-[3.75rem] pb-7 rounded-[1.25rem] before:block before:content-[''] before:w-[5.063rem] before:h-[3.375rem] before:bg-[url('../assets/img/arrow-yellow.webp')] before:bg-center before:bg-no-repeat before:absolute before:top-[50%] before:left-[50%] before:translate-x-[-50%] before:bg-contain max-sm:before:translate-y-0 max-sm:before:rotate-[90deg] max-sm:before:top-[645px] max-sm:before:w-[65px] md:mb-[2.5rem] lg:mb-[3.75rem] mb-[30px]">
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-0 font-blancomedium text-center">Idea to MVP in <span className="italic text-primary">16 Weeks</span></h2>
            <p className="text-sm mt-[1.125rem] text-center">Tutorac is an online learning platform providing video courses and live entrance exam practice tests for classes 11 and 12. </p>
            <div className="two-columns grid md:grid-cols-2 gap-8 md:pt-[2.5rem] pt-7">
              <div className="left-col bg-white-200 lg:p-[2rem] p-6 rounded-[1.25rem]">
                <div className="title-wrap flex flex-wrap md:items-center justify-between flex-col items-start md:flex-row">
                  <h3 className="font-blancomedium lg:text-[1.75rem] text-2xl md:text-xl leading-[2.188rem] pb-2">Discovery Process</h3>
                  <span className="duration text-[0.875rem] italic bg-[#F8F7F2] lg:py-[0.625rem] lg:pt-[0.625rem] lg:pb-[0.438rem] pt-[0.5rem] pb-[0.313rem] lg:px-[1.438rem] px-4 rounded-[3.688rem] md:pt-[6px] md:pb-[4px] md:px-5">2 weeks</span>
                </div>
                <div className="btch-big bg-[#E8E3FF] inline-block rounded-[6.25rem] text-[0.875rem] italic mt-[1.875rem] font-gorditamedium text-xs sm:text-sm  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]">Wireframing + Scope of work</div>
                <p className=" pt-5 pb-5 md:pb-10 min-h-[8.25rem] md:text-base text-[0.938rem]">During this stage, crucial decisions are made to set the direction of the project, manage business and user expectations.</p>
                <div className="item-img rounded-[10px] bg-[#F7F8F9]">
                  <StaticImage loading="lazy" src="../assets/img/process.png" alt="Wireframing screen of project" />
                </div>
              </div>
              <div className="right-col bg-white-200 lg:p-[2rem] p-6 rounded-[1.25rem]">
                <div className="title-wrap flex flex-wrap md:items-center justify-between flex-col items-start md:flex-row">
                  <h3 className="font-blancomedium lg:text-[1.75rem] text-2xl md:text-xl leading-[2.188rem] pb-2">3 Months MVP</h3>
                  <span className="duration text-[0.875rem] italic bg-[#F8F7F2] lg:py-[0.625rem] lg:pt-[0.625rem] lg:pb-[0.438rem] pt-[0.5rem] pb-[0.313rem] lg:px-[1.438rem] px-4 rounded-[3.688rem] md:pt-[6px] md:pb-[4px] md:px-5">3 months</span>
                </div>
                <div className="btch-big bg-[#E8E3FF] inline-block rounded-[6.25rem] text-[0.875rem] italic mt-5 lg:mt-[1.875rem]  font-gorditamedium text-xs sm:text-sm leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]">Product Launch</div>
                <p className="pt-5 pb-10 min-h-[8.25rem]">Launch the core concept of your product within 3 months to evaluate your idea in the real market.</p>
                <div className="item-img rounded-[10px] bg-[#F7F8F9]">
                  <StaticImage loading="lazy" src="../assets/img/mvp.png" alt="Figma screen of project" />
                </div>
              </div>
            </div>
          </div>



          <div className="revolutionize relative bg-[#F8F7F2] rounded-[1.25rem] md:pt-[2.813rem] pt-5  md:px-[3.125rem] px-5 md:pb-[3.75rem] pb-5">
            <div className="title-wrap">
              <h2 className="text-black text-2xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-0 font-blancomedium text-center md:max-w-[47.125rem] my-0 mx-auto">Revolutionize your products with cutting-edge <span className="italic text-primary">AI solutions</span></h2>
              <p className="text-center text-base lg:max-w-[838px] max-sm:mt-5 mt-[1.875rem] mb-[3.75rem] mx-auto">Leverage the capabilities of OpenAI API to revolutionize your web and mobile applications through  <strong className="font-gorditamedium"> <i>seamless AI integration.</i></strong> Our expertise extends to deploying AI assistants, refining search functionalities, delivering <strong className="font-gorditamedium"><i>personalized recommendations,</i></strong> constructing robust analytics, and generating stunning images, among other transformative applications.</p>
            </div>
            <div className="grid-columns 3-column grid md:grid-cols-[1fr_1fr_183px] xl:grid-cols-[496px_1fr_253px] gap-6">
              <div className="chat-item">
                <StaticImage
                  src="../assets/img/ai.webp"
                  alt="AI resume builder"
                  className="object-cover"
                  placeholder="blurred"
                />
                <div className="item-content py-[2.375rem] px-[1.875rem] md:py-5 xl:py-[1.875rem] md:px-6 xl:px-[3.813rem] bg-white-200 rounded-[1.25rem] my-[1.563rem] border border-cream">
                  <h3 className="text-[1.125rem] md:text-[1.375rem] uppercase font-gorditamedium mb-3 lg:mb-4">Whisper</h3>
                  <p className=" text-sm leading-6">A flexible speech recognition model enabling accurate transcription of spoken words.</p>
                </div>
                <StaticImage
                  src="../assets/img/chat2.png"
                  alt="Generative AI for images"
                  className="object-cover"
                  placeholder="blurred"
                />
              </div>
              <div className="chat-item">
                <div className="item-content py-[2.375rem] px-[1.875rem] md:py-[1.875rem] xl:py-[3.75rem] md:px-5 xl:px-6 bg-white-200 rounded-[1.25rem] mb-[1.563rem] border border-cream">
                  <h3 className=" text-[1.125rem] md:text-[1.375rem] uppercase font-gorditamedium mb-3 lg:mb-4">GPT 4.0</h3>
                  <p className=" text-sm leading-6">Tackling complex challenges with state-of-the-art generative AI.</p>
                </div>
                <div className="item-content py-[2.375rem] px-[1.875rem] md:py-[1.875rem] xl:py-[2.688rem] md:px-5 xl:px-6 bg-white-200 rounded-[1.25rem] border border-cream">
                  <h3 className="text-[1.125rem] md:text-[1.375rem] uppercase font-gorditamedium  mb-3 lg:mb-4">DALL-E</h3>
                  <p className=" text-sm leading-6">Unleashing creativity by crafting realistic images & art from text descriptions.</p>
                </div>
              </div>
              <div className="chat-item">
                <div className="item-content  py-[2.375rem] px-[1.875rem] md:py-[1.875rem] xl:py-[3.125rem] md:px-5 xl:px-6 bg-white-200 rounded-[1.25rem] mb-[1.563rem] border border-cream">
                  <StaticImage
                    src="../assets/img/rasa1.webp"
                    alt="RASA"
                    className=" w-[73px]"
                    placeholder="blurred"
                  />
                  <p className=" text-sm leading-6 mt-4">Crafting intelligent AI chatbots for engaging conversations.</p>
                </div>
                <StaticImage
                  src="../assets/img/ai-chat.webp"
                  alt="Demo of AI chatbot"
                  className="object-contain"
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>

          {/* <span className="connector  py-[0.625rem] md:py-[1.719rem]  block text-center relative before:content-[''] before:w-[2.875rem] before:h-[6.875rem] before:bg-[url('../assets/img/seprator.webp')] md:before:bg-center before:bg-[center_top_-15px] before:bg-no-repeat before:absolute before:top-0 before:left-[50%] before:translate-x-[-50%] before:-z-10 "><span className="rounded inline-block bg-[#D9D9D9] w-2 h-2"></span></span> */}

          {/* <div className="dedicated-team relative rounded-[1.25rem] lg:px-[3.125rem] md:px-8 px-5 bg-white-300 md:pt-[2.875rem] pt-5 lg:pb-[3.75rem] md:pb-10 pb-5">
            <div className="title-wrap">
              <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-0 font-blancomedium text-center">Dedicated and  <span className="italic text-primary">extended team</span></h2>
              <p className="text-center text-base md:text-lg lg:max-w-[838px] mt-5 md:mb-[3.125rem] mb-5 mx-auto">Seamlessly integrate our <strong className="font-gorditamedium font-normal"> <i>skilled and committed developers</i></strong> to your team and achieve your development goals.</p>
            </div>
            <div className="team-fea-grid grid md:grid-cols-2 lg:grid-cols-[1fr_400px] xl:grid-cols-[1fr_440px] md:gap-6 xl:gap-[7.5rem] gap-5 items-start">
              <div className="service-list">
                <h3 className=" text-[1.25rem] md:text-2xl font-blancomedium md:leading-[2.188rem]">Browse our most in-demand services<span className="inline-block text-orangecol">.</span></h3>
                <div className="service-grid md:grid md:grid-cols-2 md:gap-x-6 lg:gap-x-12">
                  <ul className="mt-[1.875rem] flex flex-wrap">
                    <li className="w-full">
                      <Link
                        to="/web-development/"
                        className="font-gorditamedium text-[#1B7499] relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        Web Application
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/react-js-development/"
                        className="font-gorditamedium text-[#1B7499] relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        ReactJS Frontend
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/node-js-development/"
                        className="font-gorditamedium text-[#1B7499] relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        NodeJS Backend
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/net-core-development-company/"
                        className="font-gorditamedium text-[#1B7499] relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        .NET Core
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/ui-ux-design-company/"
                        className="font-gorditamedium text-[#1B7499] relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] min-[991px]:mb-0 leading-[1.875rem] inline-block hover:italic hover:underline">
                        UI/UX Design
                      </Link>
                    </li>
                  </ul>
                  <ul className="md:mt-[1.875rem] flex flex-wrap">
                    <li className="w-full">
                      <Link
                        to="/mobile-application-development/"
                        className="font-gorditamedium text-[#1B7499] relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        Mobile Application
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/flutter-app-development/"
                        className="font-gorditamedium text-[#1B7499] relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        Flutter Application
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/ios-app-development/"
                        className="font-gorditamedium text-[#1B7499] relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        iPhone Application
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/android-app-development/"
                        className="font-gorditamedium text-[#1B7499] relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem]  mb-[0.875rem] md:mb-4 leading-[1.875rem] inline-block hover:italic hover:underline">
                        Android Application
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/aws-development-services/"
                        className="font-gorditamedium text-[#1B7499] relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-0 leading-[1.875rem] inline-block hover:italic hover:underline">
                        AWS
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="hire-team text-center bg-white-200 pt-[1.875rem] md:pb-[2.5rem] pb-[1.875rem] rounded-[1.25rem]">
                <h3 className="text-black text-lg lg:text-2xl lg:leading-[3.375rem] pb-0 font-blancomedium text-center mb-[1.125rem]">Hire <span className="italic text-primary">top 3%</span> & build your team</h3>
                <span className="duration text-[0.875rem] italic bg-[#F8F7F2] py-[0.625rem] px-5 rounded-[3.688rem] mt-[1.125rem]">starting from <strong className="font-gorditamedium">$20/hour</strong></span>
                <div className="team-images flex flex-wrap items-center justify-center mt-7 mb-[2.25rem]">
                  <div className=" w-12 h-12 bg-[#f8f7f2] flex flex-wrap items-center justify-center rounded-full overflow-hidden border border-[#D8D8D8]">
                    <StaticImage
                      src="../assets/img/team1.webp"
                      alt="Developers team of techuz"
                      className=" mt-[0.313rem]"
                      placeholder="blurred"
                    />
                  </div>
                  <div className=" w-12 h-12 bg-[#e8fbff] flex flex-wrap item-center justify-center rounded-full overflow-hidden border border-[#c3dbe3] ml-[-10px]">
                    <StaticImage
                      src="../assets/img/team2.webp"
                      alt="Developers team of techuz"
                      className=""
                      placeholder="blurred"
                    />
                  </div>
                  <div className=" w-12 h-12 bg-[#fff2e3] flex flex-wrap item-center justify-center rounded-full overflow-hidden border border-[#facab3] ml-[-10px]">
                    <StaticImage
                      src="../assets/img/team3.webp"
                      alt="Developers team of techuz"
                      className=" mt-[0.313rem]"
                      placeholder="blurred"
                    />
                  </div>
                  <div className=" w-12 h-12 bg-[#F7F8F9] flex flex-wrap item-center justify-center rounded-full overflow-hidden border border-[#cdcaf4] ml-[-10px]">
                    <StaticImage
                      src="../assets/img/team4.webp"
                      alt="Developers team of techuz"
                      className=" mt-[0.313rem]"
                      placeholder="blurred"
                    />
                  </div>
                </div>
                <Link
                  to="/contact-us/"
                  className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-black shadow-1xl text-base leading-[1.313rem] rounded-3xl pl-6 py-1 pr-1 hover:border-primary font-gorditamedium group ease-in duration-300"
                >
                  Let’s build your team
                  <span className="inline-flex bg-primary justify-center items-center rounded-full relative h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                    <StaticImage src="../assets/img/arrow-right.webp" alt="RightArrow" className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]" />
                    <StaticImage src="../assets/img/arrow-right.webp" alt="RightArrow" className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300" />
                  </span>
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      <section className="support-sec pt-6 md:py-[5rem] py-14 relative home-page-portfolio bg-[#0f1111]">
        <div className="container">
          <div className="w-full title-sec max-sm:pb-6 pb-12">
            <h2 className="text-primary text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center">
              Our Work
              <span className="inline-block text-orangecol">.</span>
            </h2>
          </div>
          <ProjectPortfolio />

          <div className="dedicated-team relative rounded-[1.25rem] lg:px-[3.125rem] md:px-8 px-5 bg-[#161616] border border-[#3a3a3b] md:pt-[2.875rem] pt-5 lg:pb-[3.75rem] md:pb-10 pb-5">
            <div className="title-wrap">
              <h2 className="text-white text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-0 font-blancomedium text-center">Contact us for Dedicated and  <span className="italic text-primary">extended team</span></h2>
              <p className="text-center text-base md:text-lg lg:max-w-[838px] mt-5 md:mb-[3.125rem] mb-5 mx-auto text-white">Seamlessly integrate our <strong className="font-gorditamedium font-normal"> <i>skilled and committed developers</i></strong> to your team and achieve your development goals.</p>
            </div>
            <div className="team-fea-grid grid md:grid-cols-2 lg:grid-cols-[1fr_400px] xl:grid-cols-[1fr_440px] md:gap-6 xl:gap-[7.5rem] gap-5 items-start">
              <div className="service-list">
                <h3 className=" text-[1.25rem] md:text-2xl font-blancomedium md:leading-[2.188rem] text-white">Browse our most in-demand services<span className="inline-block text-orangecol">.</span></h3>
                <div className="service-grid md:grid md:grid-cols-2 md:gap-x-6 lg:gap-x-12">
                  <ul className="mt-[1.875rem] flex flex-wrap">
                    <li className="w-full">
                      <Link
                        to="/web-development/"
                        className="font-gorditamedium text-white relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        Web Application
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/react-js-development/"
                        className="font-gorditamedium text-white relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        ReactJS Frontend
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/node-js-development/"
                        className="font-gorditamedium text-white relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        NodeJS Backend
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/net-core-development-company/"
                        className="font-gorditamedium text-white relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        .NET Core
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/ui-ux-design-company/"
                        className="font-gorditamedium text-white relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] min-[991px]:mb-0 leading-[1.875rem] inline-block hover:italic hover:underline">
                        UI/UX Design
                      </Link>
                    </li>
                  </ul>
                  <ul className="md:mt-[1.875rem] flex flex-wrap">
                    <li className="w-full">
                      <Link
                        to="/mobile-application-development/"
                        className="font-gorditamedium text-white relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        Mobile Application
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/flutter-app-development/"
                        className="font-gorditamedium text-white relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        Flutter Application
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/ios-app-development/"
                        className="font-gorditamedium text-white relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-[0.875rem] md:mb-4  leading-[1.875rem] inline-block hover:italic hover:underline">
                        iPhone Application
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/android-app-development/"
                        className="font-gorditamedium text-white relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem]  mb-[0.875rem] md:mb-4 leading-[1.875rem] inline-block hover:italic hover:underline">
                        Android Application
                      </Link>
                    </li>
                    <li className="w-full">
                      <Link
                        to="/aws-development-services/"
                        className="font-gorditamedium text-white relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:bg-[#FFC81D] before:rounded before:absolute before:top-[7px] before:left-0 pl-[1.125rem] mb-0 leading-[1.875rem] inline-block hover:italic hover:underline">
                        AWS
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="hire-team text-center bg-[#57d823] pt-[1.875rem] md:pb-[2.5rem] pb-[1.875rem] rounded-[1.25rem]">
                <h3 className="text-white text-lg lg:text-2xl lg:leading-[3.375rem] pb-0 font-blancomedium text-center mb-[1.125rem]">Hire <span className="italic text-black">top 3%</span> & build your team</h3>
                <span className="duration text-[0.875rem] italic bg-[#F8F7F2] py-[0.625rem] px-5 rounded-[3.688rem] mt-[1.125rem]">starting from <strong className="font-gorditamedium">$20/hour</strong></span>
                <div className="team-images flex flex-wrap items-center justify-center mt-7 mb-[2.25rem]">
                  <div className=" w-12 h-12 bg-[#f8f7f2] flex flex-wrap items-center justify-center rounded-full overflow-hidden border border-[#D8D8D8]">
                    <StaticImage
                      src="../assets/img/team1.webp"
                      alt="Developers team of techuz"
                      className=" mt-[0.313rem]"
                      placeholder="blurred"
                    />
                  </div>
                  <div className=" w-12 h-12 bg-[#e8fbff] flex flex-wrap item-center justify-center rounded-full overflow-hidden border border-[#c3dbe3] ml-[-10px]">
                    <StaticImage
                      src="../assets/img/team2.webp"
                      alt="Developers team of techuz"
                      className=""
                      placeholder="blurred"
                    />
                  </div>
                  <div className=" w-12 h-12 bg-[#fff2e3] flex flex-wrap item-center justify-center rounded-full overflow-hidden border border-[#facab3] ml-[-10px]">
                    <StaticImage
                      src="../assets/img/team3.webp"
                      alt="Developers team of techuz"
                      className=" mt-[0.313rem]"
                      placeholder="blurred"
                    />
                  </div>
                  <div className=" w-12 h-12 bg-[#F7F8F9] flex flex-wrap item-center justify-center rounded-full overflow-hidden border border-[#cdcaf4] ml-[-10px]">
                    <StaticImage
                      src="../assets/img/team4.webp"
                      alt="Developers team of techuz"
                      className=" mt-[0.313rem]"
                      placeholder="blurred"
                    />
                  </div>
                </div>
                <Link
                  to="/contact-us/"
                  className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-black shadow-1xl text-base leading-[1.313rem] rounded-3xl pl-6 py-1 pr-1 hover:border-primary font-gorditamedium group ease-in duration-300"
                >
                  Let’s build your team
                  <span className="inline-flex bg-primary justify-center items-center rounded-full relative h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                    <StaticImage src="../assets/img/arrow-right.webp" alt="RightArrow" className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]" />
                    <StaticImage src="../assets/img/arrow-right.webp" alt="RightArrow" className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showComponent && !isMobile && <section className="py-8  lg:py-10 xl:py-20 testimonial-sec md:py-[1.875rem]">
        <div className="container">
          <div className="w-full text-center lg:pb-10 pb-9">
            <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic pt-[12px] pb-[9px] px-[1.625rem] rounded-[6.25rem] mb-6 md:mb-[0.625rem] leading-[1.125rem] md:leading-[21px]">Testimonials</span></div>
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
              Here’s what <span className="italic text-black md:text-primary">our clients say</span> about us
              <span className="inline-block text-orangecol">.</span>
            </h2>
            <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">They’ve praised and highly rated our work on various review platforms.</p>
          </div>

          <TestimonialSlider />

        </div>
      </section >}
      <section className="service-sec pb-[3.125rem] lg:py-10  xl:pb-20">
        <Technologies />
        <div className="container">
          <div className="title-sec text-center pt-[2.5rem] md:pt-[3.125rem] xl:pt-20 md:max-w-[47.5rem] m-auto md:pb-10 pb-9">
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
              Cloud Engineering
              <span className="inline-block text-orangecol">.</span>
            </h2>
            <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">We have the technical prowess of industry-leading programming languages, frameworks, and tools.  And we’ll help you implement strategically fit tech stack for maximum reliability, flexibility, and scalability.</p>
          </div>
          <div className="flex flex-wrap justify-between md:flex-nowrap">
            <div className="bg-white-300 py-10 px-6 lg:px-10 lg:max-w-[calc(41.66%-0.938rem)] md:max-w-[calc(50%-0.938rem)] w-full rounded-[1.25rem] flex justify-start items-baseline text-left flex-wrap mb-5 md:mb-0">
              <ul className="flex items-center justify-start pb-5 md:pb-10">
                <li>
                  <StaticImage
                    src="../assets/img/o.png"
                    alt="Serverless architecture"
                    placeholder="blurred"
                  />
                </li>
              </ul>
              <div className="flex flex-col justify-center items-start max-w-full">
                <div className="font-gorditamedium italic text-xs sm:text-sm rounded-full bg-[#E8E3FF] leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px] mb-5">
                  Solid software architecture
                </div>
                <p className="text-base leading-[1.625rem]">
                  Build a solid software architecture with microservices,
                  serverless, and various architecture solutions to get the
                  highest reliability, efficiency, scalability, and
                  maintainability for your digital products.
                </p>
              </div>
            </div>
            <div className="bg-white-300 py-10 px-6 lg:px-10 lg:max-w-[calc(58.33%-0.938rem)] md:max-w-[calc(50%-0.938rem)] w-full rounded-[1.25rem] flex justify-between items-baseline text-left flex-wrap">
              <ul className="flex items-center justify-start pb-5 md:pb-10">
                <li className="mr-12">
                  <StaticImage
                    src="../assets/img/aws.webp"
                    alt="Aws"
                    placeholder="blurred"
                  />
                </li>
                <li className="mr-12">
                  <StaticImage
                    src="../assets/img/Group.webp"
                    alt="Azure"
                    placeholder="blurred"
                  />
                </li>
                <li>
                  <StaticImage
                    src="../assets/img/cloud.webp"
                    alt="Google cloud"
                    placeholder="blurred"
                  />
                </li>
              </ul>
              <div className="flex flex-col justify-center items-start max-w-full">
                <div className="font-gorditamedium italic text-xs sm:text-sm rounded-full bg-[#E8E3FF] leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px] mb-5">
                  Maximize the power of cloud computing
                </div>
                <p className="text-base leading-[1.625rem]">
                  Power up your products with market-leading cloud computing
                  services such as Amazon Web Services, Microsoft Azure, and
                  Google Cloud Platform. And with our certified cloud engineers on
                  board, you can be assured you’re getting maximum benefits from
                  cloud services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {!isMobile && <section className="featured-sec pb-10 md:pb-[3.125rem] xl:pb-20">
        <div className="container">
          <div className="w-full title-sec lg:pb-[3.125rem] pb-8 text-center">
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium">
              We're featured for our <span className="italic text-primary">industry knowledge</span>
              <span className="inline-block text-orangecol">.</span>
            </h2>
          </div>
          <IndustryInsights />
        </div>
      </section>}
      <Contact />
    </>
  )
}

export default Home

export const Head: HeadFC = () => {
  const { siteUrl } = useSiteMetadata()
  return (
    <>
      <SEO
        title="Web & Mobile App Development Company India, USA | IT Services and Consulting"
        canonical={`${siteUrl}`}
        description="Techuz is proudly serving startup and enterprise clients for custom web and mobile application needs. We have been developing scalable and secure apps using Angular, React, Node.js, React Native and PHP - Laravel. Let's explore how we can become your IT Partner."
      />
    </>
  )
}
